import { Link } from "react-router-dom";

import "../assets/css/footer.css";

export const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 textCenter">
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "mailto:info@renkom.com";
                e.preventDefault();
              }}
            >
              info@renkom.com
            </Link>
          </div>
          <div className="col-md-4 textCenter">
            {" "}
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "tel:469-250-4424";
                e.preventDefault();
              }}
            >
              469-250-4424
            </Link>
          </div>
          <div className="col-md-4 common-links-social textCenter">
            <a
              href="https://www.linkedin.com/company/renkom"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin-in"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCwONA2MEsvdBzTGlqth_cnA"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-youtube"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 Copyright-text">
            <p>
              © Copyright {new Date().getFullYear()} RENKOM Technologies. All
              Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
