import { useEffect, useState } from "react";
import axios from "../axios";
import "../assets/css/navbar.css";
import MenuItems from "./menuItems";
import { Link, useNavigate } from "react-router-dom";

export const Navbar = ({ mobileNavToggle }) => {
  const [data, setData] = useState({});
  const [menuItems, setMenuItems] = useState([]);
  const [lnkContact, setLnkContact] = useState({
    title: "Get Started",
    url: "/company/contact",
  });

  const navigate = useNavigate();
  useEffect(() => {
    async function getData() {
      try {
        await fetch(process.env.REACT_APP_URL + "static/data/menu.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (myJson) {
            setData(myJson);
          });
      } catch (error) {
        await axios
          .post("v1/records-with-count?limit=500&offset=0", {
            tableId: process.env.REACT_APP_MM_TABLE_ID,
            extraArgs: {
              customQueryArray: [
                {
                  operator: "=",
                  column: "text_3",
                  value: "Active",
                },
                {
                  join: "AND",
                },
                {
                  operator: "!=",
                  column: "text_8",
                  value: "Other",
                },
                {
                  join: "AND",
                },
                {
                  operator: "!=",
                  column: "text_8",
                  value: "Home",
                },
              ],
              selectFieldsArray: [
                "text_2",
                "text_1",
                "text_3",
                "text_8",
                "text_9",
                "text_5",
                "record_id",
              ],
              orderArray: ["text_9,asc"],
            },
          })
          .then((response) => {
            setData(response.data);
          });
      }
    }
    getData().then(() => {});
  }, []);
  useEffect(() => {
    if (data.data) {
      const res = data.data.map((obj) => {
        return {
          title: obj.text2,
          url: obj.text5,
          menuID: obj.text1,
          menuPosition: obj.text8,
          recordId: obj.recordId,
          className: "",
        };
      });
      const MM = groupBy(res, "menuID");
      const mainMenu = MM[null].map((obj, index) => {
        if (
          Object.keys(MM).includes(
            process.env.REACT_APP_MENU_TABLE_ALIAS + "-" + obj.recordId
          )
        ) {
          return {
            title: obj.title,
            url: obj.url,
            submenu:
              MM[process.env.REACT_APP_MENU_TABLE_ALIAS + "-" + obj.recordId],
            className: "dropdown",
          };
        } else {
          if (obj.title.toLowerCase().includes("contact")) {
            setLnkContact({
              title: obj.title,
              url: obj.url,
            });
            return false;
          } else
            return {
              title: obj.title,
              url: obj.url,
              className: "",
            };
        }
      });
      setMenuItems(mainMenu);
    }
  }, [data.data]);

  return (
    <nav className="nav-menu navbar">
      <ul className="main-ul-box">
        {menuItems.map((menu, index) => {
          return (
            <MenuItems items={menu} key={index} onClick={mobileNavToggle} />
          );
        })}
      </ul>
    </nav>
  );
};

const groupBy = (array, key) =>
  array.reduce((result, { [key]: k, ...rest }) => {
    (result[k] = result[k] || []).push(rest);
    return result;
  }, {});
