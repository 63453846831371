import React, { useState, useEffect } from "react";
import axios from "../axios";
import parse from "html-react-parser";
import { useNavigate, useResolvedPath } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import { SlideShow } from "../components/SlideShow";

const loading = process.env.REACT_APP_IMAGE_PATH + "loading.gif";
export const About = () => {
  const [content, setContent] = useState(
    "<div style='text-align:center'><img style='max-width:100%' src=" +
      loading +
      " alt='Loading...' /></div>"
  );
  const [axiosFlag, setAxiosFlag] = useState(false);
  const [data, setData] = useState({});
  const [meta, setMeta] = useState({
    title: "RENKOM Homepage",
    description: "Renkom",
    meta: {
      charset: "utf-8",
      name: {
        description: "renkom",
      },
    },
  });
  const resolvedPath = useResolvedPath();
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      try {
        await fetch(process.env.REACT_APP_URL + "static/data/data.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (myJson) {
            setData(myJson);
          });
      } catch (error) {
        setAxiosFlag(true);
        await axios
          .post("v1/records-with-count?limit=10&offset=0", {
            tableId: process.env.REACT_APP_CM_TABLE_ID,
            extraArgs: {
              customQueryArray: [
                {
                  operator: "=",
                  column: "text_3",
                  value: "Active",
                },
                {
                  join: "AND",
                },
                {
                  operator: "=",
                  column: "text_5",
                  value: resolvedPath.pathname,
                },
              ],
            },
          })
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.log("error", error);
            navigate("/not-found");
          });
      }
    }
    getData().then(() => {});
  }, []);
  useEffect(() => {
    if (axiosFlag) {
      async function getData() {
        try {
          await axios
            .post("v1/records-with-count?limit=10&offset=0", {
              tableId: process.env.REACT_APP_CM_TABLE_ID,
              extraArgs: {
                customQueryArray: [
                  {
                    operator: "=",
                    column: "text_3",
                    value: "Active",
                  },
                  {
                    join: "AND",
                  },
                  {
                    operator: "=",
                    column: "text_5",
                    value: resolvedPath.pathname,
                  },
                ],
              },
            })
            .then((response) => {
              setData(response.data);
            });
        } catch (error) {
          console.log("error", error);
          navigate("/not-found");
        }
      }
      getData().then(() => {});
    }
  }, [axiosFlag, navigate, resolvedPath.pathname]);
  useEffect(() => {
    if (data.data || Object.keys(data).includes("content-")) {
      let result = {};
      if (!axiosFlag && Object.keys(data).includes("content-")) {
        result = data["content" + resolvedPath.pathname.replaceAll("/", "-")];
      } else {
        result = data;
      }
      setContent(result.data[0].longText1);

      setMeta({
        title: result.data[0].text2,
        description: result.data[0].longText2,
        meta: {
          charset: "utf-8",
          name: {
            description: result.data[0].longText2
              ? result.data[0].longText2
              : result.data[0].text2,
          },
        },
      });
    }
  }, [axiosFlag, data, resolvedPath.pathname]);

  return (
    <DocumentMeta {...meta}>{content && <>{parse(content)}</>}</DocumentMeta>
  );
};
